<template>
  <v-container fluid>
    <splitpanes vertical class="default-theme">
      <pane size="80">
            <video-player :streamId="this.streamId"></video-player>
      </pane>
      <pane>
        <instrument-panel></instrument-panel>
      </pane>
    </splitpanes>

  </v-container>
</template>

<script>
import VideoPlayer from "../component/VideoPlayer"
import InstrumentPanel from "../component/InstrumentPanel"
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

export default {
  components: {
    VideoPlayer,
    Splitpanes,
    Pane,
    InstrumentPanel
  },
  data() {
    return {
      streamId: 99
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>
.splitpanes__pane {
  box-shadow: 0 0 3px rgba(0, 0, 0, .9) inset;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  color: inherit;
}

</style>
